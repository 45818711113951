export function margin(m: string) {
  const [top, right, bottom, left] = m.split(" ");
  return {
    marginTop: top,
    marginRight: right || top,
    marginBottom: bottom || top,
    marginLeft: left || right || top,
  };
}

export function padding(m?: string) {
  if (!m) {
    return undefined;
  }
  const [top, right, bottom, left] = m.split(" ");
  return {
    paddingTop: top,
    paddingRight: right || top,
    paddingBottom: bottom || top,
    paddingLeft: left || right || top,
  };
}

export function borderRadius(m: string) {
  const [top, right, bottom, left] = m.split(" ");
  return {
    borderTopLeftRadius: top,
    borderTopRightRadius: right || top,
    borderBottomRightRadius: bottom || top,
    borderBottomLeftRadius: left || right || top,
  };
}

export function border(m: string) {
  if (m === "none") {
    return {
      borderTopWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      borderLeftWidth: 0,
    };
  }
  return {
    borderTop: m,
    borderRight: m,
    borderBottom: m,
    borderLeft: m,
  };
}

export function borderColor(m: string) {
  return {
    borderTopColor: m,
    borderRightColor: m,
    borderBottomColor: m,
    borderLeftColor: m,
  };
}

export function layout() {
  return {
    boxSizing: "border-box" as "border-box",
    maxWidth: "1440px",
    ...padding("1rem"),
    ...margin("0 auto"),
    "@media screen and (min-width: 880px)": {
      ...padding("1rem 2rem"),
    },
  };
}
