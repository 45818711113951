import { useEffect, useState } from "react";
import LogoLoader from "../components/loader/logo";

export function setWithExpiry(key: string, value: string, ttl: number) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key: string) {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

export function ErrorFallback({ error }: { error?: Error }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // Handles failed lazy loading of a JS/CSS chunk.
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const chunkFailedName = "ChunkLoadError";
    if (
      error &&
      (chunkFailedMessage.test(error.message) || error.name === chunkFailedName)
    ) {
      if (!getWithExpiry("chunk_failed")) {
        setWithExpiry("chunk_failed", "true", 10000);
        window.location.reload();
      }
    }
  }, [error]);

  if (!visible) {
    return <LogoLoader />;
  }

  return (
    <div>
      <LogoLoader />
      <p>Načítáme novou verzi aplikace</p>
      <p>
        Pokud se nová verze nenačte, během následujícíh několika sekund,
        aktualizujte stránku manuálně
      </p>
      <pre>Error: {error?.message}</pre>
    </div>
  );
}
