import { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { useLocation } from "react-router-dom";
import { useUser } from "../components/settings-provider";

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const { email } = useUser();

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize("UA-60729481-4");
      TagManager.initialize({ gtmId: "GTM-KD56WX6" });

      const advancedMatching = { em: email } as any;
      const options = {
        autoConfig: true,
        debug: false,
      };
      ReactPixel.init("2082913891850522", advancedMatching, options);
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
      ReactPixel.pageView();
    }
  }, [initialized, location]);
};

export default usePageTracking;
