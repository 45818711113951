import createAuth0Client, { Auth0ClientOptions } from "@auth0/auth0-spa-js";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import { WebAuth } from "auth0-js";
import React, { useContext, useEffect, useState } from "react";
import LogoLoader from "./components/loader/logo";

const DEFAULT_REDIRECT_CALLBACK = () => {
  const [redirect_uri] = window.location.href.split("#");
  window.history.replaceState({}, document.title, redirect_uri);
};

export const Auth0Context = React.createContext<any>(undefined);

interface Auth0ContextProps {
  isAuthenticated: boolean;
  user: any;
  loading: boolean;
  token?: string;
  loginWithRedirect: () => void;
  login: (uri?: string) => void;
  logout: (uri?: string) => void;
  forceLogin: (uri?: string) => void;
  ensureLogin: () => void;
}

export const useAuth = () => useContext<Auth0ContextProps>(Auth0Context);

const {
  REACT_APP_AUTH_CLIENT_ID = "",
  REACT_APP_AUTH_DOMAIN = "",
} = process.env;

export const webAuth: Auth0ClientOptions = {
  client_id: REACT_APP_AUTH_CLIENT_ID,
  domain: REACT_APP_AUTH_DOMAIN,
  redirect_uri: window.location.origin,
  response_type: "code token",
  cacheLocation: "localstorage",
  ui_locales: "cs",
};

const handleRequest = () =>
  window.location.search.includes("code=") &&
  window.location.search.includes("state=");

export const AuthProvider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
}: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [user, setUser] = useState<any>();
  const [token, setToken] = useState<string>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const parseHash = async () => {
      if (window.location.hash) {
        const webAuthHash = new WebAuth({
          clientID: REACT_APP_AUTH_CLIENT_ID,
          domain: REACT_APP_AUTH_DOMAIN,
          redirectUri: window.location.origin,
          responseType: "token id_token",
          scope: "openid profile email user_metadata app_metadata picture",
        });

        return new Promise<string>((resolve, reject) => {
          webAuthHash.parseHash(
            { hash: window.location.hash },
            (error, authResult) => {
              if (authResult) {
                console.log("resolve.parseHash");
                window.location.hash = "";
                authResult.idToken && resolve(authResult.idToken);
              }
              if (error) {
                console.log("reject.parseHash");
                reject(error);
              }
            }
          );
        });
      }

      return undefined;
    };

    const initAuth0 = async () => {
      try {
        // Get rid of Auth0 for redirect_uri
        const [redirect_uri] = window.location.href.split("#");

        // Initialize Auth0 and store it locally
        const auth0FromHook = await createAuth0Client(webAuth);
        setAuth0(auth0FromHook);

        // Check if current user is authenticated
        const isCurrentAuthenticated = await auth0FromHook.isAuthenticated();

        // If he isn't, check for idToken in a hash (passwordless login)
        const idToken = !isCurrentAuthenticated && (await parseHash());

        // If there is idToken, use loginWithRedirect to reinitiallize auth with logged in user
        if (idToken) {
          await auth0FromHook.loginWithRedirect({ redirect_uri });
        } else {
          // If user isn't authenticated and there is no need to authenticate, stop loading
          if (!isCurrentAuthenticated && !handleRequest()) {
            setLoading(false);
          }

          // If there is a need to authenticate, continue to log in (but don't fail! - in case the url keeps hash code on refresh)
          try {
            if (handleRequest()) {
              const [redirect_uri] = window.location.href.split("#");
              const { appState } = await auth0FromHook.handleRedirectCallback(
                redirect_uri
              );
              onRedirectCallback(appState);
            }
          } catch (e) {
            console.log(e);
          }

          // Get idToken from claims, if there isn't any (no idea what is it)
          const token = (await auth0FromHook.getIdTokenClaims())?.__raw;
          setToken(token);

          // Check and set if user is authenticated
          const isAuthenticated = await auth0FromHook.isAuthenticated();

          setIsAuthenticated(isAuthenticated);

          // If he is, get more information about user and finish loading
          if (isAuthenticated) {
            const user = await auth0FromHook.getUser();
            setUser(user);
            setLoading(false);
          }
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
        // On unauthorized
        setLoading(false);
      }
    };

    initAuth0();
  }, []);

  const loginWithRedirect = async () => {
    login();
  };

  const forceLogin = async (uri?: string) => {
    const redirectUri = window.location.href.replace(
      window.location.origin,
      ""
    );
    window.localStorage.setItem("redirectUri", uri || redirectUri);
    if (auth0Client) {
      auth0Client.logout({ returnTo: `${window.location.origin}/login` });
    }
  };

  const login = async (uri?: string) => {
    const redirectUri = window.location.href.replace(
      window.location.origin,
      ""
    );

    window.localStorage.setItem("redirectUri", uri || redirectUri);
    if (auth0Client) {
      await auth0Client.loginWithRedirect({
        redirect_uri: `${window.location.origin}/login`,
      });
    }
  };

  const logout = (path?: string) => {
    if (auth0Client) {
      const redirectUri = window.location.href.replace(
        window.location.origin,
        ""
      );
      window.localStorage.setItem("redirectUri", path || redirectUri);

      auth0Client.logout({ returnTo: window.location.origin + "/redirect" });
    }
  };

  const ensureLogin = () => {
    if (!isAuthenticated) {
      login();
    }
  };

  if (loading) {
    return <LogoLoader />;
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        token,
        loginWithRedirect,
        login,
        logout,
        forceLogin,
        ensureLogin,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export interface WidgetBooking {
  articleId: string;
  start?: string;
  end?: string;
  name?: string;
  phone?: string;
}

export default AuthProvider;
