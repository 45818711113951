import React, { PropsWithChildren, useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import "./app.css";
import { useLanguage } from "./components/settings-provider";
import i18n from "./locale";

export default function I18n(props: PropsWithChildren<{}>) {
  const { lang } = useLanguage();

  useEffect(() => {
    if (lang) {
      if (!i18n.isInitialized) {
        i18n.init({
          debug: true,
          lng: lang,
          fallbackLng: "cs",
          interpolation: {
            escapeValue: false,
          },
          backend: {
            loadPath: "/locales/{{lng}}.json",
          },
        });
      } else {
        i18n.changeLanguage(lang);
      }
    }
  }, [lang]);

  return <I18nextProvider i18n={i18n}>{props.children}</I18nextProvider>;
}
