import * as Sentry from "@sentry/react";
import { captureException } from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { campervanTheme } from "@werein/components";
import { BaseProvider, createTheme } from "baseui";
import { mergeDeepRight } from "ramda";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import ApolloProvider from "./apollo-provider";
import App from "./app";
import AuthProvider from "./auth-provider";
import LogoLoader from "./components/loader/logo";
import SettingsProvider from "./components/settings-provider";
import { ErrorFallback } from "./error/error-fallback";
import I18n from "./i18n-provider";
import "./index.css";
import "./locale";
import reportWebVitals from "./report-web-vitals";
import * as serviceWorkerRegistration from "./service-worker-registration";
import gtag from "./utils/gtag";

const engine = new Styletron();

const theme = createTheme(
  mergeDeepRight(campervanTheme.primitives, {}),
  mergeDeepRight(campervanTheme.overrides, {
    colors: {
      inputFill: "white",
      inputColor: "var(--black-87)",
      inputTextDisabled: "var(--black-80)",
      backgroundPrimary: "rgb(252, 252, 252)",
    },
  })
);

const { REACT_APP_SENTRY_DSN = "" } = process.env;

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  blacklistUrls: [/googleapis\.com/, /mapkit\.js/],
  ignoreErrors: [
    /ChunkLoadError/,
    /Loading chunk [\d]+ failed/,
    "ResizeObserver loop limit exceeded",
    "window.ResizeObserver is not a constructor",
    /undefined is not a constructor \(evaluating \'new window.ResizeObserver/,
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  beforeSend(event) {
    // Check if it is an exception, and if so, show the report dialog
    // if (event.exception) {
    //   Sentry.showReportDialog({ eventId: event.event_id });
    // }
    return event;
  },
});

window.addEventListener("load", () => {
  try {
    (window as any).dataLayer = (window as any).dataLayer || [];
    gtag("js", new Date());
    gtag("config", "AW-492030155");
  } catch (error) {
    captureException(error);
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<LogoLoader />}>
        <AuthProvider>
          <ApolloProvider>
            <StyletronProvider value={engine} debugAfterHydration>
              <SettingsProvider>
                <I18n>
                  <BaseProvider theme={theme}>
                    <BrowserRouter>
                      <App />
                    </BrowserRouter>
                  </BaseProvider>
                </I18n>
              </SettingsProvider>
            </StyletronProvider>
          </ApolloProvider>
        </AuthProvider>
      </Suspense>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
