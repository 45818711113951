import { gql, useMutation, useQuery } from "@apollo/client";
import { Cashify } from "cashify";
import { format, parseISO } from "date-fns";
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth } from "../auth-provider";
import { localeDateFns } from "../utils/date-fns";
import useCashify from "../utils/use-cashify";

const GET_CURRENT_ACCOUNT = gql`
  query currentUserQuery {
    currentUser {
      _id
      name
      email
      phone
      currency
      lang
    }
    accountLocation {
      latitude
      longitude
    }
  }
`;

const UPDATE_ACCOUNT = gql`
  mutation updateCurrentAccount($account: AccountInput!) {
    updateCurrentAccount(account: $account) {
      currency
      lang
    }
  }
`;

export enum Languages {
  cs = "cs",
  en = "en",
  de = "de",
}

interface SettingsContext {
  lang: Languages;
  currency: string;
  setCurrency: (currency: string) => void;
  setLang: (lang: Languages) => void;
  cashify: Cashify;
  latitude: number;
  longitude: number;
  name: string;
  email: string;
  phone: string;
}

export const SettingsContext = React.createContext<SettingsContext>({
  currency: "CZK",
  setCurrency: () => {},
  lang: undefined as any,
  setLang: () => {},
  cashify: new Cashify({}),
  latitude: 0,
  longitude: 0,
  name: "",
  email: "",
  phone: "",
});

export function useCurrency() {
  const { currency, setCurrency, cashify } = useContext(SettingsContext);
  const { priceFormat } = useLanguage();

  function convert(
    amount: number | undefined,
    inputCurrency: string,
    fallback?: string
  ) {
    if (!amount) {
      return fallback;
    }

    const formatted = priceFormat(
      cashify.convert(amount, {
        from: inputCurrency,
        to: currency,
      }),
      currency
    );

    return formatted;
  }

  const translatedCurrency = priceFormat(1, currency)?.replace("1", "");

  return {
    currency,
    setCurrency,
    cashify,
    convert,
    translatedCurrency,
  };
}

export function useUser() {
  const { latitude, longitude, name, email, phone } = useContext(
    SettingsContext
  );

  return {
    name,
    email,
    phone,
    latitude,
    longitude,
  };
}

export function useLanguage() {
  const { lang, setLang } = useContext(SettingsContext);

  const dateFormat = (date: string | Date, props: { long?: boolean } = {}) => {
    const day = date instanceof Date ? date : parseISO(date);
    return format(day, props.long ? "PPP" : "PP", {
      locale: localeDateFns(lang),
    });
  };

  const priceFormat = (
    price: number | undefined,
    currency?: string | undefined
  ) => {
    return price?.toLocaleString(lang, {
      style: "currency",
      currency,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });
  };

  return {
    lang,
    setLang,
    host: "https://campervan.cz",
    dateFormat,
    priceFormat,
  };
}

function defaultCurrency(lang: Languages) {
  switch (lang) {
    case Languages.cs:
      return "CZK";
    default:
      return "EUR";
  }
}

function getDefaultLang() {
  return (localStorage.getItem("lang") as Languages) || Languages.cs;
}

function getDefaulCurrency(lang?: Languages) {
  return localStorage.getItem("currency") || (lang && defaultCurrency(lang));
}

export default function SettingsProvider(props: PropsWithChildren<{}>) {
  const { isAuthenticated } = useAuth();
  const { data } = useQuery<{
    currentUser: {
      currency?: string;
      lang?: Languages;
      name?: string;
      email?: string;
      phone?: string;
    };
    accountLocation: {
      latitude: number;
      longitude: number;
    };
  }>(GET_CURRENT_ACCOUNT);
  const [updateAccount] = useMutation(UPDATE_ACCOUNT);
  const [cashify] = useCashify();

  const [lang, setStateLang] = useState<Languages | undefined>(
    isAuthenticated ? undefined : getDefaultLang()
  );

  const [currency, setStateCurrency] = useState(
    isAuthenticated ? undefined : getDefaulCurrency(lang)
  );

  const setCurrency = (value: string) => {
    if (value) {
      if (isAuthenticated) {
        updateAccount({
          variables: { account: { currency: value } },
        });
      } else {
        localStorage.setItem("currency", value);
      }
    }
    setStateCurrency(value);
  };

  const setLang = (value: Languages) => {
    if (value) {
      if (isAuthenticated) {
        updateAccount({
          variables: { account: { lang: value } },
        });
      } else {
        localStorage.setItem("lang", value);
      }
    }
    setStateLang(value);
  };

  useEffect(() => {
    if (isAuthenticated && data?.currentUser) {
      const l = data?.currentUser?.lang || getDefaultLang();
      const c = data?.currentUser?.currency || getDefaulCurrency(l);
      c !== currency && setStateCurrency(c);
      l !== lang && setStateLang(l);
    }
  }, [data, isAuthenticated]);

  return (
    <SettingsContext.Provider
      value={{
        currency: currency || "CZK",
        setCurrency,
        cashify,
        lang: lang || Languages.cs,
        setLang,
        latitude: data?.accountLocation.latitude || 0,
        longitude: data?.accountLocation.longitude || 0,
        name: data?.currentUser?.name || "",
        email: data?.currentUser?.email || "",
        phone: data?.currentUser?.phone || "",
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
}
