import { SnackbarProvider } from "baseui/snackbar";
import React, { ComponentType, lazy, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory } from "react-router";
import "./app.css";
import Footer from "./app/footer";
import Header from "./app/header";
import Layout from "./app/layout";
import { useAuth } from "./auth-provider";
import SEO from "./components/seo";
import Zendesk from "./components/zendesk";
import usePageTracking from "./utils/use-ga";

function LoginScreen() {
  const { login, isAuthenticated } = useAuth();
  const { replace } = useHistory();
  useEffect(() => {
    const redirectUri = window.localStorage.getItem("redirectUri");
    window.localStorage.removeItem("redirectUri");
    if (isAuthenticated) {
      replace(redirectUri || "/");
    } else {
      login(redirectUri || undefined);
    }
  }, []);

  return <></>;
}

function ScrollScreen() {
  const { location } = useHistory();
  const [t] = useTranslation();
  useEffect(() => {
    if (
      !location.pathname.startsWith("/places/") &&
      !location.search.includes("pg")
    ) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <SEO
      title={t("articles.title")}
      keywords={[
        "campervan",
        "pronájem",
        "půjčovna",
        "obytný vůz",
        "karavan",
        "obytná dodávka",
      ]}
      pathname="/"
      image={{
        src: "https://campervan.cz/logo.png",
        width: 312,
        height: 312,
      }}
      description={t("articles.description")}
    />
  );
}

function RedirectScreen() {
  const { replace } = useHistory();
  useEffect(() => {
    const redirectUri = window.localStorage.getItem("redirectUri");
    window.localStorage.removeItem("redirectUri");
    replace(redirectUri || "/");
  }, []);

  return <></>;
}

const IndexScreen = lazy(() => import("./screens/index"));
const BookScreen = lazy(() => import("./screens/book"));
const WScreen = lazy(() => import("./screens/w"));
const WidgetScreen = lazy(() => import("./screens/widget"));
const AdsIndexScreen = lazy(() => import("./screens/ads"));
const HostScreen = lazy(() => import("./info/host"));
const Routes = lazy(() => import("./routes"));
const PublicRoutes = lazy(() => import("./routes/public"));

export default function App() {
  usePageTracking();

  return (
    <SnackbarProvider>
      <>
        <Route path="/" component={ScrollScreen} />

        <Switch>
          <Route exact path="/redirect" component={RedirectScreen} />
          <Route exact path="/login" component={LoginScreen} />
          <Route exact path="/ads" component={AdsIndexScreen} />
          <Route exact path="/host" component={HostScreen} />
          <Route exact path="/w/:id" component={WScreen} />
          <Route exact path="/widget" component={WidgetScreen} />
          <Route path="/book" component={BookScreen} />

          <Layout>
            <Zendesk />

            <Header />
            <div>
              <Switch>
                <Route exact path="/" component={IndexScreen} />
                <Route
                  path="/:entity(article|articles|pronajem)"
                  component={PublicRoutes}
                />
                <Route path="/" component={Routes} />
              </Switch>
            </div>

            <Footer />
          </Layout>
        </Switch>
      </>
    </SnackbarProvider>
  );
}
