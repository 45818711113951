import i18n from "i18next";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const { REACT_APP_VERSION = "1" } = process.env;

const instance = i18n
  .use(initReactI18next)
  .use(new backend(undefined, { queryStringParams: { v: REACT_APP_VERSION } }));

export default instance;
