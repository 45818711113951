import { FC } from "react";
import { isIOS } from "react-device-detect";
import { useStyletron } from "styletron-react";

const Layout: FC = (props) => {
  const [css] = useStyletron();

  return (
    <div
      className={css({
        minHeight: isIOS ? "-webkit-fill-available" : "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
      })}
    >
      {props.children}
    </div>
  );
};

export default Layout;
