import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as Apollo,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/link-error";
import * as Sentry from "@sentry/react";
import React, { PropsWithChildren } from "react";
import { useAuth } from "./auth-provider";

const httpLink = new HttpLink({ uri: "/graphql" });

const cache = new InMemoryCache({});

const link = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      Sentry.captureMessage(message)
    );
  if (networkError) {
    Sentry.captureException(networkError);
  }
});

function UnauthorizedApolloProvider({ children }: PropsWithChildren<{}>) {
  const client = new ApolloClient({
    cache,
    link: link.concat(httpLink),
  });

  return <Apollo client={client}>{children}</Apollo>;
}

export default function ApolloProvider({ children }: PropsWithChildren<{}>) {
  const { token, isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <UnauthorizedApolloProvider>{children}</UnauthorizedApolloProvider>;
  }

  const authLink = new ApolloLink((operation, forward) => {
    // NO BEARER prefix!
    operation.setContext({
      headers: {
        authorization: token || "",
      },
    });

    return forward(operation);
  });

  const client = new ApolloClient({
    cache,
    link: link.concat(authLink).concat(httpLink),
  });

  return <Apollo client={client}>{children}</Apollo>;
}
