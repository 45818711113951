import { Button as BaseButton, KIND, SIZE } from "baseui/button";
import React, { PropsWithChildren, ReactNode, Ref } from "react";
import { StyleObject, useStyletron } from "styletron-react";
import { border, borderRadius, padding } from "../utils/css";

export type ButtonProps = PropsWithChildren<{
  ref?: Ref<any>;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
  style?: StyleObject;
  radius?: "square" | "round";
  appearance?:
    | KIND["minimal"]
    | KIND["primary"]
    | KIND["secondary"]
    | "outline";
  size?: "medium" | "large";
  isLoading?: boolean;
  disabled?: boolean;
  type?: "submit" | "button";
  icon?: ReactNode;
}>;

const Button = (props: ButtonProps) => {
  const [css] = useStyletron();
  const { radius = "round" } = props;
  const kind =
    props.appearance === "outline" ? KIND["minimal"] : props.appearance;
  return (
    <BaseButton
      ref={props.ref}
      overrides={{
        BaseButton: {
          style: () => ({
            ...border(
              props.appearance === "outline" ? "solid 2px var(--gray)" : "none"
            ),
            ...borderRadius(radius === "round" ? "2rem" : "1.2rem"),
            ...padding(props.size === "large" ? "1rem" : undefined),
            ...props.style,
          }),
        },
      }}
      type={props.type || "button"}
      onClick={props.onClick}
      size={SIZE.compact}
      kind={kind}
      isLoading={props.isLoading}
      disabled={props.disabled}
    >
      {props.icon && (
        <span className={css({ marginRight: "0.5rem" })}>{props.icon}</span>
      )}
      {props.children}
    </BaseButton>
  );
};

export default Button;
