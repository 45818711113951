import { useStyletron } from "styletron-react";
import logo from "../../app/logo.png";

export default function LogoLoader(props: { relative?: boolean }) {
  const [css] = useStyletron();

  const content = (
    <div id="suspense">
      <img src={logo} />
    </div>
  );

  if (props.relative) {
    return (
      <div
        className={css({
          position: "relative",
          minHeight: "48px",
        })}
      >
        {content}
      </div>
    );
  }

  return content;
}
