import {
  Button,
  ButtonAppearance,
  LinkButton,
  NavButton,
} from "@werein/components";
import React, { FC, useState } from "react";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useStyletron } from "styletron-react";
import { useAuth } from "../auth-provider";
import useWindowSize from "../helpers/window-size";
import { padding } from "../utils/css";
import logo2 from "./logo-2.png";
import logo from "./logo.png";

export const Header: FC<{}> = () => {
  const [css] = useStyletron();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { isAuthenticated, login } = useAuth();
  const [isOpen, setOpen] = useState(false);

  return (
    <div
      className={css({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
        backgroundColor: "white",
        ...padding("0.5rem 1rem"),
        "@media screen and (max-width: 720px)": {
          alignItems: "flex-start",
        },
      })}
    >
      <Link
        to="/"
        className={css({
          display: "flex",
          alignItems: "center",
          "@media screen and (max-width: 720px)": {
            position: "absolute",
          },
        })}
      >
        <img
          src={logo}
          className={css({
            height: "43px",
          })}
        />
        <img
          src={logo2}
          className={css({
            height: "18px",
            marginLeft: "1rem",
          })}
        />
      </Link>

      <div
        className={css({
          display: "grid",
          gridTemplateColumns: "auto auto auto auto auto",
          gap: "1rem",
          "@media screen and (max-width: 720px)": {
            gap: "0.5rem",
            gridTemplateColumns: "auto",
            flexDirection: "column-reverse",
            flexGrow: 1,
          },
        })}
      >
        <div
          className={css({
            display: "flex",
            justifyContent: "flex-end",
            "@media screen and (min-width: 720px)": {
              display: "none",
            },
          })}
        >
          <Button
            onClick={() => setOpen(!isOpen)}
            appearance={ButtonAppearance.outline}
          >
            {isOpen ? <Icon.X size={20} /> : <Icon.Menu size={20} />}
          </Button>
        </div>

        {(isOpen || width > 720) && (
          <>
            <NavButton
              appearance={ButtonAppearance.minimal}
              to="/host"
              startEnhancer={<Icon.PlusCircle size={16} />}
            >
              {t("app.header.advertise")}
            </NavButton>
            <LinkButton
              appearance={ButtonAppearance.tertiary}
              to="/pronajem"
              startEnhancer={<Icon.Search size={16} />}
            >
              <span>{t("app.header.search")}</span>
            </LinkButton>

            <NavButton
              appearance={ButtonAppearance.minimal}
              to="/places"
              startEnhancer={<Icon.Map size={16} />}
            >
              <span>{t("app.header.places")}</span>
            </NavButton>
            <NavButton
              appearance={ButtonAppearance.minimal}
              to="/blog"
              startEnhancer={<Icon.BookOpen size={16} />}
            >
              Blog
            </NavButton>
            {isAuthenticated && (
              <NavButton
                to="/care/home"
                startEnhancer={<Icon.User size={16} />}
              >
                {t("app.header.my-account")}
              </NavButton>
            )}
            {!isAuthenticated && (
              <Button
                appearance={ButtonAppearance.secondary}
                onClick={() => login()}
              >
                {t("app.header.login")}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
