import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import { useStyletron } from "styletron-react";
import { useAuth } from "../auth-provider";
import Button from "../components/button";
import CurrencySelector from "../components/currency/currency-selector";
import LangSelector from "../components/lang-selector";
import NavButton from "../components/nav-button";
import { margin, padding } from "../utils/css";

const Footer = () => {
  const [css] = useStyletron();
  const [t] = useTranslation();
  const { logout, isAuthenticated, login } = useAuth();
  return (
    <div
      className={css({
        borderTop: "solid 1px rgba(0, 0, 0, 0.05)",
        backgroundColor: "rgb(255, 255, 255)",
      })}
    >
      <div
        className={css({
          maxWidth: "1440px",
          margin: "0 auto 3rem auto",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          boxSizing: "border-box",
          ...padding("1rem"),
          "@media screen and (max-width: 960px)": {
            flexDirection: "column-reverse",
          },
        })}
      >
        <div
          className={css({
            ...padding("0.5rem 0"),
          })}
        >
          <div
            className={css({
              display: "grid",
              gridAutoFlow: "column",
              gap: "0.5rem",
              "@media screen and (max-width: 720px)": {
                gridAutoFlow: "row",
              },
            })}
          >
            <div
              className={css({
                display: "flex",
                justifyContent: "space-between",
                gap: "0.5rem",
              })}
            >
              <a
                className={css({
                  display: "flex",
                  alignItems: "center",
                  ...margin("0 0 0 0.5rem"),
                })}
                href="https://instagram.com/campervan.cz"
                target="_blank"
              >
                <Icon.Instagram size="24" />
              </a>
              <a
                className={css({
                  display: "flex",
                  alignItems: "center",
                })}
                href="https://web.facebook.com/camprvan"
                target="_blank"
              >
                <Icon.Facebook size="24" />
              </a>
              {isAuthenticated && (
                <span
                  className={css({
                    display: "flex",
                    alignItems: "center",
                    ...margin("0 0 0 0.5rem"),
                  })}
                  onClick={() => logout()}
                >
                  <Icon.LogOut size="24" />
                </span>
              )}
            </div>
            <NavButton
              appearance="minimal"
              to="/blog/clanek/6062d1f524aa9a000740d861"
            >
              {t("footer.about-us")}
            </NavButton>
            {/* {!isAuthenticated && (
              <Button appearance="minimal" onClick={() => login()}>
                {t("app.header.login")}
              </Button>
            )} */}
            {isAuthenticated && (
              <Button appearance="minimal" onClick={() => logout()}>
                {t("app.header.logout")}
              </Button>
            )}
            <NavButton
              target="_blank"
              to="https://support.campervan.cz/hc/cs/articles/360019856600"
              appearance="minimal"
            >
              {t("app.foter.how-advertise")}
            </NavButton>
            {/* {isAuthenticated && (
              <NavButton
                to="/care/home"
                appearance="minimal"
                icon={<Icon.User size={16} />}
              >
                {t("app.header.my-account")}
              </NavButton>
            )} */}
            {/* <NavButton appearance="minimal" to="/about-us">
              {t("footer.about-us")}
            </NavButton> */}
            {/* <NavButton
              appearance="minimal"
              to="https://pronajem.campervan.cz/"
              target="_blank"
            >
              {t("footer.our-rental")}
            </NavButton> */}
            <NavButton
              appearance="minimal"
              to="https://support.campervan.cz"
              target="_blank"
            >
              {t("app.header.help")}
            </NavButton>

            <NavButton appearance="minimal" to="/conditions">
              {t("footer.conditions")}
            </NavButton>
          </div>
        </div>
        <div
          className={css({
            display: "grid",
            gridAutoFlow: "column",
            gap: "0.5rem",
            marginBottom: "1rem",
          })}
        >
          <LangSelector />
          <CurrencySelector />
        </div>
      </div>
    </div>
  );
};

export default Footer;
